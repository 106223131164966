import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import NavigationBar, {
    NavigationSetting,
    DraftSelector,
} from "../controls/NavigationBar";
import { DraftBoardSelector } from "../controls/PlayerboardSelector";
import PlayerboardPage from "../app-pages/PlayerboardPage";
import Toggle from "../controls/buttons/Toggle";
import useAuth from "../hooks/use-auth";
import { uiActions } from "../store/ui-slice";
import { mp_track } from "../mixpanel";

const draft_board_mp_track = (
    event,
    competition,
    season,
    properties = null
) => {
    properties = {
        ...properties,
        competition_id: competition.id,
        competition_name: competition.display_name,
        season_id: season.id,
        season_name: season.name,
    };

    mp_track(event, properties);
};

const DraftBoard = ({
    initialDraftId,
    onMenuOpen,
    viewContextProp,
    onViewContextChange,
}) => {
    const { checkPermission } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const drafts = useSelector((state) => state.playerboards.drafts);
    const competitions = useSelector(
        (state) => state.competitions.competitions
    );
    const seasons = useSelector((state) => state.seasons.seasons);

    const draftViewSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.draftViewSeasonId
    );

    const canEditDraftSelections = checkPermission(
        "core.can_edit_draft_selections"
    );

    // State to keep track of the selected draft
    const [selectedDraftId, setSelectedDraftId] = useState(initialDraftId);

    // Effect to set initial draft if not provided
    useEffect(() => {
        if (!selectedDraftId) {
            const initialDraft = Object.values(drafts).find(
                (draft) => draft.season_id === draftViewSeasonId
            );
            if (initialDraft) {
                setSelectedDraftId(initialDraft.id);
            }
        }
    }, [drafts, draftViewSeasonId, selectedDraftId]);

    // Handler for draft selection
    const handleSelectDraft = useCallback(
        (draftId) => {
            dispatch(uiActions.showLoader());

            // Wait for the loader to show before changing the draft
            setTimeout(() => {
                setSelectedDraftId(draftId);
                const selectedDraft = drafts[draftId];
                draft_board_mp_track(
                    "Draft Selected",
                    competitions[selectedDraft.competition_id],
                    seasons[selectedDraft.season_id],
                    { draft_id: draftId }
                );
                // Update the navigation URL, and create a new entry in the browser history
                navigate(`/app/draft/${draftId}`);
            }, 100);
        },
        [dispatch, drafts, navigate, competitions, seasons]
    );

    // Get the current draft
    const currentDraft = drafts[selectedDraftId];

    const [playerboardId, setPlayerboardId] = useState(null);

    useEffect(() => {
        if (currentDraft) {
            setPlayerboardId(currentDraft.playerboard_id);
        }
    }, [currentDraft]);

    const bgColor =
        currentDraft && currentDraft.background_color !== ""
            ? currentDraft.background_color
            : null;
    const imgUrl =
        currentDraft && currentDraft.logo_url !== ""
            ? currentDraft.logo_url
            : null;
    const imgPosition =
        currentDraft && currentDraft.logo_position !== ""
            ? currentDraft.logo_position
            : null;
    const metricsSeasonId = currentDraft?.season_id;

    const onBoardIdSelect = useCallback((boardId) => {
        setPlayerboardId(boardId);
    }, []);

    const onToggleTeamSelectionMode = useCallback(() => {
        navigate(`/app/draft/${selectedDraftId}/mark`);
        //setTimeout(() => {
        //    setAlphabeticalSort(!alphabeticalSort);
        //    setListVisible(true);
        //}, 300);
    }, [selectedDraftId, navigate]);

    // If no draft is selected or found, render nothing
    if (!currentDraft) return null;

    return (
        <PlayerboardPage
            boardMode={"Draft"}
            playerboardId={playerboardId}
            viewContextProp={viewContextProp}
            onViewContextChange={onViewContextChange}
            onMenuOpen={onMenuOpen}
            metricsSeason={metricsSeasonId}
            bgColor={bgColor}
            imgUrl={imgUrl}
            imgPosition={imgPosition}
            showProjectedFreeAgencyStatus={false}
            showDraftTargetStatus={true}
            showDraftTeamOverlay={true}
        >
            <NavigationBar>
                <DraftSelector
                    drafts={drafts}
                    selectedDraftId={selectedDraftId}
                    onSelectDraft={handleSelectDraft}
                />
                <NavigationSetting>
                    <DraftBoardSelector
                        draftId={selectedDraftId}
                        selectedBoardId={playerboardId}
                        onBoardIdSelect={onBoardIdSelect}
                    />
                </NavigationSetting>
                {canEditDraftSelections && (
                    <NavigationSetting label="Team Selection Mode">
                        <Toggle
                            isChecked={false}
                            onToggle={onToggleTeamSelectionMode}
                        />
                    </NavigationSetting>
                )}
            </NavigationBar>
        </PlayerboardPage>
    );
};

export default DraftBoard;
