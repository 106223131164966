import classes from "./PlayerIndexDrilldownPanel.module.css";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import SlideInPanel from "../../layout/SlideInPanel";
import ExpandableSection from "../../layout/ExpandableSection.js";
import PlayerHeader from "../player-header/PlayerHeader";
import OverheadTitleSection from "../../layout/OverheadTitleSection";
import {
    fetchPlayerDatastash,
    fetchPlayer,
    patchPlayerScoutingRevisedProjection,
} from "../../store/players-actions.js";
import {
    DialogControl,
    PostModalDialog,
} from "../../controls/dialogs/ModalDialog";
import { TieredNumericButtonIndicator } from "../indicators.js";
import OptionDropdown from "../../controls/OptionDropdown";
import PlayerSeasonPerformanceView from "../player-season-performance-view/PlayerSeasonPerformanceView";
import { PLAYER_PROJECTION_BG_COLORS } from "../../constants";
import {
    getPrimaryPosition,
    shortDateString,
    getPerformanceTierFromPercentile,
    getOrdinal,
} from "../../utils";
import SquareButton from "../../controls/buttons/SquareButton";
import HorizontalBarIndicator from "../../controls/HorizontalBarIndicator";
import HorizontalDistributionIndicator from "../../controls/HorizontalDistributionIndicator";
import useAuth from "../../hooks/use-auth";
import useWindowDimensions from "../../hooks/use-window-dimensions.js";

import { mp_track } from "../../mixpanel";

const player_index_drilldown_mp_track = (
    event,
    player,
    competition,
    season,
    properties = null
) => {
    properties = {
        ...properties,
        player_id: player.id,
        player_name: player.known_name,
        competition_id: competition?.id,
        competition_name: competition?.display_name,
        season_id: season?.id,
        season_name: season?.name,
    };

    mp_track(event, properties);
};

const ScoutingProjectionIndicator = ({ projection }) => {
    return (
        <div className={classes.scouting_projection}>
            <div
                className={classes.consensus_scouting_projection}
                style={{
                    backgroundColor: PLAYER_PROJECTION_BG_COLORS[projection],
                }}
            >
                {projection}
            </div>
        </div>
    );
};

const ScoutingProjectionOption = ({ optionValue }) => {
    return (
        <div className={classes.scouting_projection_option_container}>
            {optionValue?.id !== "None" ? (
                <ScoutingProjectionIndicator projection={optionValue?.id} />
            ) : (
                <div className={classes.scouting_projection_consensus_option}>
                    {optionValue.label}
                </div>
            )}
        </div>
    );
};

export const PlayerOverrideScoutingProjectionDialog = ({
    player,
    isOpen,
    onCloseHandler,
}) => {
    const dispatch = useDispatch();

    const projectionOptions = useMemo(() => {
        const playerPrimaryPosition = getPrimaryPosition(player?.position);
        if (playerPrimaryPosition === "G") {
            return [
                { id: "None", label: "Use consensus" },
                { id: "G1", label: "G1" },
                { id: "G2", label: "G2" },
                { id: "G3", label: "G3" },
                { id: "G4", label: "G4" },
                { id: "G5", label: "G5" },
            ];
        } else if (playerPrimaryPosition === "D") {
            return [
                { id: "None", label: "Use consensus" },
                { id: "D1", label: "D1" },
                { id: "D2", label: "D2" },
                { id: "D3", label: "D3" },
                { id: "D4", label: "D4" },
                { id: "D5", label: "D5" },
                { id: "D6", label: "D6" },
                { id: "D7", label: "D7" },
                { id: "D8", label: "D8" },
                { id: "D9", label: "D9" },
                { id: "D10", label: "D10" },
            ];
        }
        return [
            { id: "None", label: "Use consensus" },
            { id: "F1", label: "F1" },
            { id: "F2", label: "F2" },
            { id: "F3", label: "F3" },
            { id: "F4", label: "F4" },
            { id: "F5", label: "F5" },
            { id: "F6", label: "F6" },
            { id: "F7", label: "F7" },
            { id: "F8", label: "F8" },
            { id: "F9", label: "F9" },
            { id: "F10", label: "F10" },
            { id: "F11", label: "F11" },
            { id: "F12", label: "F12" },
            { id: "F13", label: "F13" },
            { id: "F14", label: "F14" },
            { id: "F15", label: "F15" },
        ];
    }, [player]);

    const [selectedProjectionOption, setSelectedProjectionOption] =
        useState(null);

    useEffect(() => {
        if (isOpen) {
            setSelectedProjectionOption(
                projectionOptions.filter(
                    (o) => o.id === player?.scouting_revised_projection
                )[0] || projectionOptions[0]
            );
        }
    }, [isOpen, player, projectionOptions]);

    // Function to handle cancel/close
    const handleClose = useCallback(() => {
        setSelectedProjectionOption(null);
        onCloseHandler && onCloseHandler();
    }, [onCloseHandler]);

    const handleCancel = () => {
        handleClose();
        player_index_drilldown_mp_track(
            "Player Override Scouting Projection Dialog Cancelled",
            player
        );
    };

    // Function to handle post
    const handlePost = useCallback(() => {
        dispatch(
            patchPlayerScoutingRevisedProjection(
                player.id,
                selectedProjectionOption.id === "None"
                    ? ""
                    : selectedProjectionOption.id
            )
        );

        handleClose();

        const mixpanelData = {
            scouting_revised_projection:
                selectedProjectionOption.id === "None"
                    ? ""
                    : selectedProjectionOption.id,
        };

        player_index_drilldown_mp_track(
            "Set Player Scouting Revised Projection",
            player,
            null,
            null,
            mixpanelData
        );
    }, [dispatch, player, selectedProjectionOption, handleClose]);

    return (
        <PostModalDialog
            title={player?.known_name + " Projection"}
            isOpen={isOpen}
            handlePost={handlePost}
            handleCancel={handleCancel}
            isPostDisabled={false}
            postLabel={"Set"}
        >
            <>
                <DialogControl singleLine={true} label={"Consensus"}>
                    <ScoutingProjectionIndicator
                        projection={player?.scouting_consensus_projection}
                    />
                </DialogControl>
                <DialogControl singleLine={true} label={"Adjusted"}>
                    <OptionDropdown
                        options={projectionOptions}
                        selectedOption={selectedProjectionOption}
                        onSelect={(option) =>
                            setSelectedProjectionOption(option)
                        }
                        OptionComponent={ScoutingProjectionOption}
                    />
                </DialogControl>
            </>
        </PostModalDialog>
    );
};

function ScoutingRoundup({
    player,
    scoutingReportSummaries,
    scoutingSummaryMode,
    wideLayout = true,
    onProjectionControlClick,
}) {
    const { checkPermission } = useAuth();

    const canOverridePlayerProjectionConsensus = checkPermission(
        "core.can_override_player_projection_consensus"
    );
    const projection =
        player &&
        (player.scouting_revised_projection !== ""
            ? player.scouting_revised_projection
            : player.scouting_consensus_projection !== ""
            ? player.scouting_consensus_projection
            : scoutingReportSummaries?.projection_report &&
              scoutingReportSummaries.projection_report.consensus_projection);
    const isRevisedProjection =
        player && player.scouting_revised_projection !== "";

    const scoutingRoundupText =
        scoutingReportSummaries &&
        (scoutingSummaryMode === "draft"
            ? scoutingReportSummaries.draft_progress_report?.progression_report
            : scoutingReportSummaries.pro_scouting_summary
                  ?.pro_scouting_summary);

    const projections = {};
    if (scoutingReportSummaries) {
        scoutingReportSummaries.projection_report &&
            scoutingReportSummaries.projection_report.scout_projections.forEach(
                (scout_projection) => {
                    if (projections[scout_projection.projection]) {
                        projections[scout_projection.projection].push(
                            scout_projection
                        );
                    } else {
                        projections[scout_projection.projection] = [
                            scout_projection,
                        ];
                    }
                }
            );
    }

    // find the scouting report where the last_seen date is the most recent
    const lastSeenProjection =
        scoutingReportSummaries?.projection_report?.scout_projections.reduce(
            (acc, scout_projection) => {
                if (!acc) {
                    return scout_projection;
                }
                if (
                    new Date(scout_projection.last_seen) >
                    new Date(acc.last_seen)
                ) {
                    return scout_projection;
                }
                return acc;
            },
            null
        );

    const improvementAreas =
        scoutingSummaryMode === "draft"
            ? scoutingReportSummaries?.consensus_attributes_report
                  ?.improvement_areas
            : scoutingReportSummaries?.consensus_attributes_report?.weaknesses;

    return (
        <div
            className={
                classes.scouting_roundup_container +
                (!scoutingReportSummaries ||
                Array.isArray(scoutingReportSummaries) ||
                !scoutingRoundupText ||
                scoutingRoundupText === ""
                    ? " " + classes.no_data
                    : "") +
                (!wideLayout ? " " + classes.narrow_layout : "")
            }
        >
            {(!scoutingReportSummaries ||
                Array.isArray(scoutingReportSummaries) ||
                !scoutingRoundupText ||
                scoutingRoundupText === "") &&
                "No scouting reports available."}
            {scoutingReportSummaries &&
                !Array.isArray(scoutingReportSummaries) && (
                    <div className={classes.scouting_report}>
                        {scoutingRoundupText && scoutingRoundupText !== "" && (
                            <div>{scoutingRoundupText}</div>
                        )}
                        {(scoutingReportSummaries.consensus_attributes_report ||
                            projection) && (
                            <div className={classes.consensus_attributes}>
                                {projection && (
                                    <div
                                        className={
                                            classes.scouting_roundup_section
                                        }
                                    >
                                        <div
                                            className={
                                                classes.scouting_roundup_section_header
                                            }
                                        >
                                            Projection
                                        </div>
                                        <div
                                            className={
                                                classes.scouting_roundup_section_content
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.scouting_projection_report
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.scouting_projection_container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.scouting_projection_control +
                                                            (canOverridePlayerProjectionConsensus
                                                                ? " " +
                                                                  classes.can_override
                                                                : "")
                                                        }
                                                        onClick={
                                                            canOverridePlayerProjectionConsensus
                                                                ? () =>
                                                                      onProjectionControlClick &&
                                                                      onProjectionControlClick()
                                                                : undefined
                                                        }
                                                    >
                                                        <ScoutingProjectionIndicator
                                                            projection={
                                                                projection
                                                            }
                                                        />
                                                        {canOverridePlayerProjectionConsensus && (
                                                            <div
                                                                className={
                                                                    classes.scouting_projection_override +
                                                                    (isRevisedProjection
                                                                        ? " " +
                                                                          classes.revised
                                                                        : "")
                                                                }
                                                            >
                                                                <SquareButton
                                                                    label={
                                                                        "exposure"
                                                                    }
                                                                    useMaterialIcon={
                                                                        true
                                                                    }
                                                                    style={
                                                                        isRevisedProjection
                                                                            ? {
                                                                                  color: "var(--tw-orange-300)",
                                                                              }
                                                                            : {}
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {projections && (
                                                    <div
                                                        className={
                                                            classes.scouting_projection_distribution
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.scouting_projection_distribution_label
                                                            }
                                                        >
                                                            Spread
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.scouting_projection_distribution_stack
                                                            }
                                                        >
                                                            {Object.keys(
                                                                projections
                                                            )
                                                                .sort(
                                                                    (a, b) => {
                                                                        const numA =
                                                                            parseInt(
                                                                                a.substring(
                                                                                    1
                                                                                ),
                                                                                10
                                                                            );
                                                                        const numB =
                                                                            parseInt(
                                                                                b.substring(
                                                                                    1
                                                                                ),
                                                                                10
                                                                            );
                                                                        return (
                                                                            numA -
                                                                            numB
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        projection,
                                                                        index
                                                                    ) => (
                                                                        // Make the height of the div equal to 1.5em * the number of scouts who projected this level, and the color the color of the projection
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={
                                                                                classes.scouting_projection_distribution_stack_segment
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes.scouting_projection_distribution_stack_segment_bar +
                                                                                    (index ===
                                                                                    0
                                                                                        ? " " +
                                                                                          classes.top
                                                                                        : "") +
                                                                                    (index ===
                                                                                    Object.keys(
                                                                                        projections
                                                                                    )
                                                                                        .length -
                                                                                        1
                                                                                        ? " " +
                                                                                          classes.bottom
                                                                                        : "")
                                                                                }
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        PLAYER_PROJECTION_BG_COLORS[
                                                                                            projection
                                                                                        ],
                                                                                }}
                                                                            >
                                                                                {
                                                                                    projection
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                className={
                                                                                    classes.scouting_projection_distribution_stack_segment_scouts
                                                                                }
                                                                            >
                                                                                {projections[
                                                                                    projection
                                                                                ].map(
                                                                                    (
                                                                                        scout,
                                                                                        index
                                                                                    ) => (
                                                                                        <div
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className={
                                                                                                classes.scouting_projection_distribution_stack_segment_scout
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                scout.name
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {lastSeenProjection && (
                                                <div
                                                    className={
                                                        classes.scouting_last_seen
                                                    }
                                                >
                                                    Last seen by{" "}
                                                    {lastSeenProjection.name} on{" "}
                                                    {shortDateString(
                                                        lastSeenProjection.last_seen
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {scoutingReportSummaries.consensus_attributes_report &&
                                    scoutingReportSummaries
                                        .consensus_attributes_report?.strengths
                                        ?.length > 0 && (
                                        <>
                                            <div
                                                className={
                                                    classes.scouting_roundup_section
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.scouting_roundup_section_header
                                                    }
                                                >
                                                    Strengths
                                                </div>
                                                <div
                                                    className={
                                                        classes.scouting_roundup_section_content
                                                    }
                                                >
                                                    <ul>
                                                        {scoutingReportSummaries.consensus_attributes_report.strengths.map(
                                                            (attr, index) => (
                                                                <li key={index}>
                                                                    {attr}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.scouting_roundup_section
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.scouting_roundup_section_header
                                                    }
                                                >
                                                    {scoutingSummaryMode ===
                                                    "draft"
                                                        ? "To Improve"
                                                        : "Weaknesses"}
                                                </div>
                                                <div
                                                    className={
                                                        classes.scouting_roundup_section_content
                                                    }
                                                >
                                                    <ul>
                                                        {improvementAreas?.map(
                                                            (attr, index) => (
                                                                <li key={index}>
                                                                    {attr}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </div>
                        )}
                    </div>
                )}
        </div>
    );
}

function CombineReport({
    player,
    playerCombineData,
    cohortCombineData,
    expandComponentClickHandler,
}) {
    const components = [
        {
            label: "Anaerobic",
            name: "anaerobic",
            metrics: [
                {
                    label: "Peak Power (w)",
                    name: "wingate_peak_power",
                    decimalPlaces: 0,
                },
                {
                    label: "Relative Peak Power",
                    name: "wingate_rel_peak_power",
                    decimalPlaces: 1,
                },
                {
                    label: "Fatigue Index",
                    name: "wingate_fatigue_index",
                    decimalPlaces: 1,
                    negative_directionality: true,
                },
            ],
        },
        {
            label: "Aerobic",
            name: "aerobic",
            metrics: [
                {
                    label: "VO2 Max",
                    name: "vo2_max",
                    decimalPlaces: 1,
                },
                {
                    label: "VO2 Final Workload",
                    name: "vo2_final_workload",
                    decimalPlaces: 1,
                },
                {
                    label: "VO2 Total Time",
                    name: "vo2_total_time_s",
                    isDuration: true,
                },
            ],
        },
        {
            label: "Strength",
            name: "strength",
            metrics: [
                {
                    label: "Bench Press",
                    name: "bench_press",
                    decimalPlaces: 1,
                },
                {
                    label: "Pull-up",
                    name: "pull-ups",
                    decimalPlaces: 1,
                },
            ],
        },
        {
            label: "Agility",
            name: "agility",
            metrics: [
                {
                    label: "Pro Agility R",
                    name: "pro_agility_r",
                    decimalPlaces: 2,
                    negative_directionality: true,
                },
                {
                    label: "Pro Agility L",
                    name: "pro_agility_l",
                    decimalPlaces: 2,
                    negative_directionality: true,
                },
            ],
        },
        {
            label: "Power",
            name: "power",
            metrics: [
                {
                    label: "Vertical Jump",
                    name: "vertical_jump",
                    decimalPlaces: 1,
                },
                {
                    label: "Long Jump",
                    name: "long_jump",
                    decimalPlaces: 1,
                },
                {
                    label: "Squat Jump",
                    name: "squat_jump",
                    decimalPlaces: 1,
                },
            ],
        },
    ];

    const maxComponentZscore =
        cohortCombineData &&
        Object.values(cohortCombineData).reduce((maxZscore, playerData) => {
            components.forEach((component) => {
                const playerComponent = playerData.component_metrics.find(
                    (metric) => metric.name === component.name
                );
                if (
                    playerComponent &&
                    Math.abs(playerComponent.zscore) > maxZscore
                ) {
                    maxZscore = Math.abs(playerComponent.zscore);
                }
            });
            return maxZscore;
        }, 0);

    const metricsMap = playerCombineData?.metrics.reduce((acc, metric) => {
        acc[metric.name] = metric;
        return acc;
    }, {});

    const cohortSamples =
        cohortCombineData &&
        Object.values(cohortCombineData).reduce((acc, playerData) => {
            playerData.metrics.forEach((metric) => {
                if (metric.value !== undefined) {
                    if (!acc[metric.name]) {
                        acc[metric.name] = [];
                    }
                    acc[metric.name].push({
                        value: metric.value,
                        zscore: metric.zscore,
                    });
                }
            });
            return acc;
        }, {});

    const playerComponents =
        playerCombineData &&
        playerCombineData.component_metrics.reduce((acc, component) => {
            acc[component.name] = component;
            return acc;
        }, {});

    return (
        playerCombineData && (
            <div className={classes.combine_report}>
                <div className={classes.combine_report_section}>
                    {playerCombineData.notes &&
                        (playerCombineData.notes !== "" ? (
                            <div className={classes.combine_report_notes}>
                                {playerCombineData.notes
                                    .split("\n")
                                    .map((note, index) => (
                                        <p key={index}>{note}</p>
                                    ))}
                            </div>
                        ) : (
                            ""
                        ))}
                    {playerCombineData.total_athleticism_score !== 0 && (
                        <div className={classes.combine_report_metrics}>
                            <div
                                className={
                                    classes.combine_report_metrics_section
                                }
                            >
                                <div
                                    className={
                                        classes.combine_report_metrics_label
                                    }
                                >
                                    Total Athleticism
                                </div>
                                <div
                                    className={
                                        classes.combine_report_metrics_value
                                    }
                                >
                                    {playerCombineData.total_athleticism_score && (
                                        <>
                                            <div
                                                className={
                                                    classes.total_athleticism_score
                                                }
                                            >
                                                <TieredNumericButtonIndicator
                                                    value={
                                                        playerCombineData.total_athleticism_score
                                                    }
                                                    tierClass={
                                                        getPerformanceTierFromPercentile(
                                                            playerCombineData.total_athleticism_score_percentile_rank
                                                        ).tier
                                                    }
                                                    precision={2}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes.total_athleticism_rank
                                                }
                                            >
                                                {getOrdinal(
                                                    playerCombineData.total_athleticism_rank
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {!playerCombineData.total_athleticism_score && (
                                        <div className={classes.no_data}>
                                            No data
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    classes.combine_report_metrics_section +
                                    " " +
                                    classes.combine_report_components_section
                                }
                            >
                                {components.map((component, index) => {
                                    const hasSubMetrics =
                                        playerCombineData?.metrics.find(
                                            (metric) =>
                                                metric.component ===
                                                    component.name &&
                                                metric.value !== undefined
                                        );

                                    return (
                                        <div
                                            className={
                                                classes.component_container
                                            }
                                            key={index}
                                        >
                                            {hasSubMetrics && (
                                                <ExpandableSection
                                                    onExpandToggle={
                                                        expandComponentClickHandler
                                                            ? (isExpanded) =>
                                                                  expandComponentClickHandler(
                                                                      isExpanded,
                                                                      component
                                                                  )
                                                            : null
                                                    }
                                                    label={
                                                        <div
                                                            className={
                                                                classes.combine_report_components
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.combine_report_component_label
                                                                }
                                                            >
                                                                {
                                                                    component.label
                                                                }
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.combine_report_component_bar_container
                                                                }
                                                            >
                                                                {playerComponents?.[
                                                                    component
                                                                        .name
                                                                ]?.zscore ? (
                                                                    <HorizontalBarIndicator
                                                                        value={
                                                                            playerComponents?.[
                                                                                component
                                                                                    .name
                                                                            ]
                                                                                ?.zscore
                                                                        }
                                                                        performanceTier={
                                                                            getPerformanceTierFromPercentile(
                                                                                playerComponents?.[
                                                                                    component
                                                                                        .name
                                                                                ]
                                                                                    ?.percentile_rank
                                                                            )
                                                                                .tier
                                                                        }
                                                                        decimalPlaces={
                                                                            2
                                                                        }
                                                                        maxValue={
                                                                            maxComponentZscore
                                                                        }
                                                                        isSigned={
                                                                            true
                                                                        }
                                                                        includeLabel={
                                                                            true
                                                                        }
                                                                        labelWidth={
                                                                            "32px"
                                                                        }
                                                                        labelColor={
                                                                            "var(--tw-gray-500)"
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        className={
                                                                            classes.no_data
                                                                        }
                                                                    >
                                                                        {hasSubMetrics
                                                                            ? "Incomplete"
                                                                            : "No data"}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    }
                                                    border={true}
                                                >
                                                    {component.metrics.map(
                                                        (metric, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={
                                                                        classes.combine_report_metric
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.combine_report_metric_label
                                                                        }
                                                                    >
                                                                        {
                                                                            metric.label
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.combine_report_metric_distribution_container
                                                                        }
                                                                    >
                                                                        {metricsMap?.[
                                                                            metric
                                                                                .name
                                                                        ]
                                                                            ?.value ? (
                                                                            <HorizontalDistributionIndicator
                                                                                value={
                                                                                    metricsMap?.[
                                                                                        metric
                                                                                            .name
                                                                                    ]
                                                                                        ?.value
                                                                                }
                                                                                percentile={
                                                                                    metricsMap?.[
                                                                                        metric
                                                                                            .name
                                                                                    ]
                                                                                        ?.percentile_rank
                                                                                }
                                                                                dataPoints={
                                                                                    cohortSamples?.[
                                                                                        metric
                                                                                            .name
                                                                                    ]
                                                                                }
                                                                                includeLabel={
                                                                                    true
                                                                                }
                                                                                decimalPlaces={
                                                                                    metric.decimalPlaces
                                                                                }
                                                                                isDuration={
                                                                                    metric.isDuration
                                                                                }
                                                                                negativeDirectionality={
                                                                                    metric.negative_directionality
                                                                                }
                                                                                showAverage={
                                                                                    true
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            "No data"
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </ExpandableSection>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

function PlayerIndexDrilldownPanel({
    playerId,
    competitionId,
    seasonId,
    metricContext,
    onMetricContextChange,
    peersData,
    isOpen,
    onCloseHandler,
    targetWidthHandler,
    showScoutingSummary = false,
    scoutingSummaryMode = "pro",
    showDraftTargetStatus = false,
    combineData,
}) {
    const dispatch = useDispatch();

    const baseContentWidth = 370;
    const contentPaddingWidth = 20;

    const [isSeasonViewLoading, setIsSeasonViewLoading] = useState(false);

    const [isProjectionDialogOpen, setIsProjectionDialogOpen] = useState(false);

    const playerCombineData = combineData && combineData[playerId];

    const { widthClasses } = useWindowDimensions([
        { min: 1110, max: Infinity },
    ]);

    const initialWideLayout = widthClasses[0]?.matches || false;
    const initialTargetWidth = initialWideLayout
        ? 2 * baseContentWidth + contentPaddingWidth
        : baseContentWidth + contentPaddingWidth;

    const [wideLayout, setWideLayout] = useState(initialWideLayout);
    const [targetWidth, setTargetWidth] = useState(initialTargetWidth);

    useEffect(() => {
        if (isOpen) {
            targetWidthHandler && targetWidthHandler(targetWidth);
        } else {
            targetWidthHandler && targetWidthHandler(null);
        }
    }, [isOpen, targetWidth, targetWidthHandler]);

    useEffect(() => {
        const newWideLayout = widthClasses[0]?.matches || false;
        setWideLayout(newWideLayout);
        const newWidth = newWideLayout
            ? 2 * baseContentWidth + contentPaddingWidth
            : baseContentWidth + contentPaddingWidth;
        setTargetWidth(newWidth);
    }, [widthClasses, baseContentWidth, contentPaddingWidth]);

    const [confirmedPlayerId, setConfirmedPlayerId] = useState(null);
    const [confirmedCompetitionId, setConfirmedCompetitionId] = useState(null);
    const [confirmedSeasonId, setConfirmedSeasonId] = useState(null);

    const players = useSelector((state) => state.players.players);
    const player = players[confirmedPlayerId];

    const player_ds = useSelector((state) => state.players.datastashes);

    const [playerChangeWait, setPlayerChangeWait] = useState(false);

    useEffect(() => {
        setPlayerChangeWait(true);
        setTimeout(() => {
            setConfirmedPlayerId(playerId);
            setConfirmedCompetitionId(competitionId);
            setConfirmedSeasonId(seasonId);
            setPlayerChangeWait(false);
        }, 175);
    }, [playerId, competitionId, seasonId]);

    const player_career_performance_summary =
        confirmedPlayerId &&
        player_ds &&
        player_ds[confirmedPlayerId] &&
        player_ds[confirmedPlayerId].career_performance_summary;

    useEffect(() => {
        if (confirmedPlayerId && !player_career_performance_summary) {
            dispatch(
                fetchPlayerDatastash(
                    confirmedPlayerId,
                    "career_performance_summary"
                )
            );
        }
    }, [confirmedPlayerId, player_career_performance_summary, dispatch]);

    useEffect(() => {
        if (playerId && !players[playerId]) {
            dispatch(fetchPlayer(playerId));
        }
    }, [playerId, players, dispatch]);

    // Find the player's performance summary for the selected competition
    // and season in the list of competition perforamce summaries
    const player_performance_summary =
        player_career_performance_summary &&
        player_career_performance_summary.competitions
            .find((comp) => comp.id === confirmedCompetitionId)
            ?.seasons.find((season) => season.season_id === confirmedSeasonId);

    const competition = useSelector(
        (state) => state.competitions.competitions[confirmedCompetitionId]
    );

    const season = useSelector(
        (state) => state.seasons.seasons[confirmedSeasonId]
    );

    useEffect(() => {
        if (player && competition && season) {
            player_index_drilldown_mp_track(
                "View Player Index Drilldown",
                player,
                competition,
                season
            );
        }
    }, [player, competition, season]);

    const onClose = () => {
        player_index_drilldown_mp_track(
            "Player Index Drilldown Closed",
            player,
            competition,
            season
        );
        onCloseHandler();
    };

    const scouting_reports_summaries =
        confirmedPlayerId &&
        showScoutingSummary &&
        player_ds &&
        player_ds[confirmedPlayerId] &&
        player_ds[confirmedPlayerId].scouting_reports_summaries;

    useEffect(() => {
        if (
            confirmedPlayerId &&
            showScoutingSummary &&
            !scouting_reports_summaries
        ) {
            dispatch(
                fetchPlayerDatastash(
                    confirmedPlayerId,
                    "scouting_reports_summaries"
                )
            );
        }
    }, [
        confirmedPlayerId,
        showScoutingSummary,
        scouting_reports_summaries,
        dispatch,
    ]);

    const isLoading = isSeasonViewLoading || playerChangeWait;

    // Create a map of projections to counts
    const projections = {};
    if (scouting_reports_summaries) {
        scouting_reports_summaries.projection_report &&
            scouting_reports_summaries.projection_report.scout_projections.forEach(
                (scout_projection) => {
                    if (projections[scout_projection.projection]) {
                        projections[scout_projection.projection] += 1;
                    } else {
                        projections[scout_projection.projection] = 1;
                    }
                }
            );
    }

    return (
        <>
            <PlayerOverrideScoutingProjectionDialog
                player={player}
                isOpen={isProjectionDialogOpen}
                onCloseHandler={() => setIsProjectionDialogOpen(false)}
            />
            <SlideInPanel
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={onClose}
                targetWidth={targetWidth}
                side="right"
            >
                {confirmedPlayerId && (
                    <>
                        <div className={classes.player_header_container}>
                            <PlayerHeader
                                player={{
                                    ...player,
                                    ...(player_performance_summary &&
                                        player_performance_summary.pillars_count !==
                                            undefined && {
                                            pillars_count:
                                                player_performance_summary.pillars_count,
                                        }),
                                }}
                                metrics={
                                    player_performance_summary && {
                                        ...player_performance_summary.metrics,
                                        games_played:
                                            player_performance_summary.games_played,
                                        games_played_is_sample:
                                            player_performance_summary.games_played_is_sample,
                                    }
                                }
                                trad_stats={
                                    player_performance_summary &&
                                    player_performance_summary
                                        .eliteprospects_stats?.regular
                                }
                                narrowLayout={targetWidth < 700}
                                performanceViewContext={{
                                    competitionId: confirmedCompetitionId,
                                    seasonId: confirmedSeasonId,
                                    metricStack: metricContext.stack,
                                    selectedMetric: metricContext.activeMetric,
                                }}
                                showDraftTargetStatus={showDraftTargetStatus}
                            />
                        </div>
                        {showScoutingSummary && (
                            <>
                                <OverheadTitleSection
                                    title={"Scouting Roundup"}
                                ></OverheadTitleSection>
                                <ScoutingRoundup
                                    player={player}
                                    scoutingReportSummaries={
                                        scouting_reports_summaries
                                    }
                                    scoutingSummaryMode={scoutingSummaryMode}
                                    wideLayout={wideLayout}
                                    onProjectionControlClick={() =>
                                        setIsProjectionDialogOpen(true)
                                    }
                                />
                            </>
                        )}
                        {playerCombineData && (
                            <>
                                <OverheadTitleSection
                                    title={"Combine Report"}
                                ></OverheadTitleSection>
                                <CombineReport
                                    player={player}
                                    playerCombineData={playerCombineData}
                                    cohortCombineData={combineData}
                                    expandComponentClickHandler={(
                                        isExpanded,
                                        component
                                    ) => {
                                        player_index_drilldown_mp_track(
                                            `${
                                                isExpanded
                                                    ? "Expand"
                                                    : "Collapse"
                                            } Combine Report Component`,
                                            player,
                                            competition,
                                            season,
                                            {
                                                component: component.name,
                                            }
                                        );
                                    }}
                                />
                            </>
                        )}
                        <OverheadTitleSection
                            title={
                                (competition
                                    ? competition.display_name + " "
                                    : "") + "Player Performance"
                            }
                        ></OverheadTitleSection>
                        <PlayerSeasonPerformanceView
                            playerId={confirmedPlayerId}
                            seasonId={confirmedSeasonId}
                            competitionId={confirmedCompetitionId}
                            metricContext={metricContext}
                            onMetricContextChange={onMetricContextChange}
                            peersData={peersData}
                            wideLayout={wideLayout}
                            isLoadingHandler={setIsSeasonViewLoading}
                        />
                    </>
                )}
            </SlideInPanel>
        </>
    );
}

export default PlayerIndexDrilldownPanel;
