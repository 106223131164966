import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { uiActions } from "../store/ui-slice";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchPlayerboardData,
    movePlayer,
    insertRow,
    removeRow,
} from "../store/playerboards-actions";
import { fetchTeam } from "../store/teams-actions";
import { fetchCompetitionActiveTeams } from "../store/competitions-actions";
import MainBox from "../layout/MainBox";
import { player_layout_positions } from "../constants";
import classes from "./Playerboard.module.css";
import PlayerGrid from "./player-grid/PlayerGrid";
import { mp_track } from "../mixpanel";
import useAuth from "../hooks/use-auth";
import { v4 as uuidv4 } from "uuid";
import playertile_classes from "./playertile/Playertile.module.css";
import RadioButtonGroup from "../controls/buttons/RadioButtonGroup";
import OptionRadioBar from "../controls/OptionRadioBar";

const Playerboard = ({
    playerboardId,
    boardCompetitionId, // Will force the player tiles to show data from a specific competition
    onPlayerSelected,
    onTileViewModeChange,
    metricsSeason = null,
    showProjectedFreeAgencyStatus = true,
    showDraftTargetStatus = false,
    showDraftTeamOverlay = false,
    showOwningTeamOverlay = false,
    combineData = null,
    capTileModeAvailable = false,
    alternateColumnLabels = null,
    rowCountersGroupSize = null,
    boardEventsPrefix, // "Roster", "Trade" or "Draft", this is used to have distinct MixPanel events depending on the mode
    onContentLoading = null,
    showLoader = true,
}) => {
    const player_board_mp_track = useCallback(
        (
            playerboardId,
            playerboardName,
            tileViewMode,
            event,
            properties = null
        ) => {
            properties = {
                ...properties,
                playerboard_id: playerboardId,
                playerboard_name: playerboardName,
                tile_view_mode: tileViewMode,
            };

            mp_track(
                (boardEventsPrefix ? boardEventsPrefix + " " : "") + event,
                properties
            );
        },
        [boardEventsPrefix]
    );

    const { isAuthenticated, user, checkPermission } = useAuth();
    const dispatch = useDispatch();
    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );
    const seasonId = metricsSeason || currentSeasonId;

    const reduxPlayerTileMode = useSelector((state) => state.ui.playerTileMode);
    const [tileViewMode, setTileViewMode] = useState(() =>
        !capTileModeAvailable && reduxPlayerTileMode === "cap"
            ? "scouting"
            : reduxPlayerTileMode
    );

    const board = useSelector(
        (state) => state.playerboards.playerboards[playerboardId]
    );

    useEffect(() => {
        const baseTileViewMode =
            !capTileModeAvailable && reduxPlayerTileMode === "cap"
                ? "scouting"
                : reduxPlayerTileMode;

        if (tileViewMode !== baseTileViewMode) {
            setTileViewMode(baseTileViewMode);
            onTileViewModeChange?.(baseTileViewMode);
        }
    }, [
        reduxPlayerTileMode,
        capTileModeAvailable,
        onTileViewModeChange,
        tileViewMode,
    ]);

    const handleTileViewModeChange = (mode) => {
        setTileViewMode(mode);
        dispatch(uiActions.setPlayerTileMode(mode));
        player_board_mp_track(
            playerboardId,
            board?.name,
            mode,
            "Set Tile View Mode",
            {
                new_mode: mode,
            }
        );
        onTileViewModeChange && onTileViewModeChange(mode);
    };
    const canViewContractInformation = checkPermission(
        "core.can_view_contract_information"
    );
    const canEditScoutingBoards = checkPermission(
        "core.can_edit_scouting_boards"
    );
    const canUpdatePrivateBoards = checkPermission(
        "core.can_update_private_boards"
    );

    useEffect(() => {
        if (playerboardId && tileViewMode) {
            player_board_mp_track(
                playerboardId,
                board?.name,
                tileViewMode,
                "Playerboard Viewed"
            );
        }
    }, [playerboardId, tileViewMode, board, player_board_mp_track]);

    const canUpdateBoard =
        board &&
        board.is_readonly === false &&
        user &&
        ((board.owner === null && canEditScoutingBoards) ||
            (board.owner !== null &&
                (canUpdatePrivateBoards || board.owner.email === user.email)));

    const players = useSelector((state) => state.players.players);

    const players_datastashes = useSelector(
        (state) => state.players.datastashes
    );

    const nhl_activeteams = useSelector(
        (state) => state.competitions.activeTeams[1]
    );

    const arizona_team_id = 2;
    const arizona_historical = useSelector(
        (state) => state.teams.teams[arizona_team_id]
    );

    const ahl_activeteams = useSelector(
        (state) => state.competitions.activeTeams[2]
    );

    const [updatingBoard, setUpdatingBoard] = useState(false);
    const lastMoveRef = useRef(false);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (board && updatingBoard) {
            setUpdatingBoard(false);
        } else {
            onContentLoading && onContentLoading(true);
        }
    }, [board, updatingBoard, onContentLoading]);

    const board_metrics_map = useMemo(() => {
        if (board) {
            return board.players.reduce((metrics_map, current_player) => {
                const season_metrics =
                    players_datastashes[current_player.player.id] &&
                    players_datastashes[current_player.player.id]
                        .career_performance_summary &&
                    players_datastashes[
                        current_player.player.id
                    ].career_performance_summary.competitions.reduce(
                        (selected_metrics, competition) => {
                            // Find the selected season in the seasons array
                            const season = competition.seasons.find(
                                (season) => season.season_id === seasonId
                            );
                            // If no competition is forced, the metrics for
                            // each player tile will be based on the player's
                            // current tier. If the player's tier is JCE, we
                            // will pick the metrics for the season with the
                            // most games played.
                            if (
                                season &&
                                ((boardCompetitionId &&
                                    competition.id === boardCompetitionId) ||
                                    (!boardCompetitionId &&
                                        ((current_player.player.tier ===
                                            "NHL" &&
                                            competition.id === 1) ||
                                            (current_player.player.tier ===
                                                "AHL" &&
                                                competition.id === 2) ||
                                            (current_player.player.tier ===
                                                "JCE" &&
                                                ![1, 2].includes(
                                                    competition.id
                                                ) &&
                                                (!selected_metrics.metrics ||
                                                    season.games_played >
                                                        selected_metrics.games_played)))))
                            ) {
                                selected_metrics.metrics = season.metrics;
                                selected_metrics.eliteprospects_stats =
                                    season.eliteprospects_stats;
                                selected_metrics.games_played_is_sample =
                                    season.games_played_is_sample;
                                selected_metrics.games_played =
                                    season.games_played;
                                selected_metrics.competition.id =
                                    competition.id;
                                selected_metrics.competition.name =
                                    competition.name;
                                selected_metrics.competition.logo_url =
                                    competition.logo_src;
                                selected_metrics.pillars_count =
                                    season.pillars_count;
                            }
                            return selected_metrics;
                        },
                        { competition: { id: null }, metrics: null }
                    );

                // If the board is in the Draft mode, and we have combine data for the player,
                // then we will use the combine data to populate the Total Athleticism Score (TAS)
                // and TAS percentile rank metrics.
                if (combineData && combineData[current_player.player.id]) {
                    season_metrics.metrics = { ...season_metrics.metrics };
                    season_metrics.metrics.TAS =
                        combineData[
                            current_player.player.id
                        ].total_athleticism_score;
                    season_metrics.metrics.TASperc =
                        combineData[
                            current_player.player.id
                        ].total_athleticism_score_percentile_rank;
                    season_metrics.metrics.TASrank =
                        combineData[
                            current_player.player.id
                        ].total_athleticism_rank;
                }

                metrics_map[current_player.player.id] = season_metrics;
                return metrics_map;
            }, {});
        }
    }, [board, seasonId, players_datastashes, boardCompetitionId, combineData]);

    const [rows, setRows] = useState(null);
    // This ensures that the board is reset when the playerboardId changes,
    // which avoids context inconsistencies with beautiful dnd.
    useEffect(() => {
        setRows(null);
    }, [playerboardId]);

    const [ws, setWs] = useState(null);

    // Create WebSocket connection.
    useEffect(() => {
        if (isAuthenticated && playerboardId) {
            const websocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL +
                    `playerboards/${playerboardId}/`
            );
            setWs(websocket);
        }
    }, [playerboardId, isAuthenticated]);

    // Connection opened, setup message receiving
    useEffect(() => {
        if (playerboardId && ws) {
            ws.onopen = () => {
                console.log(
                    `Playerboard ${playerboardId} WebSocket connected.`
                );
            };

            // Listen for messages
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (lastMoveRef.current !== data.request_id) {
                    console.log(
                        `Playerboard ${playerboardId} WebSocket update received.`
                    );
                    setUpdatingBoard(true);
                    dispatch(fetchPlayerboardData(playerboardId));
                } else {
                    lastMoveRef.current = null;
                }
            };

            // Connection closed
            ws.onclose = () => {
                console.log(
                    `Playerboard ${playerboardId} WebSocket disconnected.`
                );
            };
        }

        // Close WebSocket connection when component unmounts
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [ws, playerboardId, dispatch]);

    const handlePlayerMoved = (
        playerId,
        newRow,
        newPosition,
        srcRow,
        srcPosition
    ) => {
        setRows((curRows) => {
            const newRows = [...curRows];
            const moved_player = newRows[srcRow - 1][srcPosition];
            newRows[srcRow - 1][srcPosition] = null;

            let bumped_player_index = newRow - 1;
            let bumped_player = newRows[bumped_player_index][newPosition];
            newRows[bumped_player_index][newPosition] = moved_player;

            while (bumped_player) {
                const temp_player = bumped_player;
                if (newPosition === srcPosition && newRow > srcRow) {
                    bumped_player_index -= 1;
                } else {
                    bumped_player_index += 1;
                }
                bumped_player = newRows[bumped_player_index][newPosition];
                newRows[bumped_player_index][newPosition] = temp_player;
            }
            return newRows;
        });

        player_board_mp_track(
            playerboardId,
            board?.name,
            tileViewMode,
            "Player moved on board",
            {
                player_id: playerId,
                player_name: players[playerId].known_name,
                new_row: newRow,
                new_position: newPosition,
                src_row: srcRow,
                src_position: srcPosition,
            }
        );

        setUpdatingBoard(true);
        lastMoveRef.current = uuidv4();

        // If the user does not have permission to move players, then the movePlayer action will fail.
        // We need to catch that error and reset the board.
        const onErrorHandler = (error) => {
            setReset(true);
        };

        dispatch(
            movePlayer(
                playerboardId,
                playerId,
                newRow,
                newPosition,
                lastMoveRef.current,
                onErrorHandler
            )
        );
    };

    const handleInsertRow = (newRowIndex) => {
        setRows((curRows) => {
            const newRows = [...curRows];

            // Insert a new row at the specified index
            newRows.splice(newRowIndex - 1, 0, {
                G: null,
                LD: null,
                RD: null,
                LW: null,
                C: null,
                RW: null,
            });

            return newRows;
        });

        player_board_mp_track(
            playerboardId,
            board?.name,
            tileViewMode,
            "Insert row on board",
            {
                new_row_index: newRowIndex,
            }
        );

        setUpdatingBoard(true);
        lastMoveRef.current = uuidv4();

        // If the user does not have permission to move players, then the movePlayer action will fail.
        // We need to catch that error and reset the board.
        const onErrorHandler = (error) => {
            setReset(true);
        };

        dispatch(
            insertRow(
                playerboardId,
                newRowIndex,
                null,
                lastMoveRef.current,
                onErrorHandler
            )
        );
    };

    const handleRemoveRow = (newRowIndex) => {
        setRows((curRows) => {
            const newRows = [...curRows];

            // Remove the row at the specified index
            newRows.splice(newRowIndex - 1, 1);

            return newRows;
        });

        player_board_mp_track(
            playerboardId,
            board?.name,
            tileViewMode,
            "Remove row on board",
            {
                new_row_index: newRowIndex,
            }
        );

        setUpdatingBoard(true);
        lastMoveRef.current = uuidv4();

        // If the user does not have permission to move players, then the movePlayer action will fail.
        // We need to catch that error and reset the board.
        const onErrorHandler = (error) => {
            setReset(true);
        };

        dispatch(
            removeRow(
                playerboardId,
                newRowIndex,
                null,
                lastMoveRef.current,
                onErrorHandler
            )
        );
    };

    const playerBoardIdRef = useRef(null);

    useEffect(() => {
        if (
            playerboardId &&
            playerBoardIdRef.current !== playerboardId &&
            !updatingBoard
        ) {
            dispatch(fetchPlayerboardData(playerboardId));
            setUpdatingBoard(true);
            if (showLoader) {
                dispatch(uiActions.showLoader());
            }
            onContentLoading && onContentLoading(true);
            playerBoardIdRef.current = playerboardId;
        }
    }, [
        playerboardId,
        boardCompetitionId,
        updatingBoard,
        playerBoardIdRef,
        showLoader,
        onContentLoading,
        dispatch,
    ]);

    useEffect(() => {
        // The active teams will be fetched by the app component, but we
        // want to make sure that they are available before we render the board.
        // Otherwise, the team overlays will flicker as they are added to the board.
        if (!nhl_activeteams) {
            if (showLoader) {
                dispatch(uiActions.showLoader());
            }
            onContentLoading && onContentLoading(true);
        }

        if (!arizona_historical) {
            dispatch(fetchTeam(arizona_team_id));
            if (showLoader) {
                dispatch(uiActions.showLoader());
            }
            onContentLoading && onContentLoading(true);
        }

        if (!ahl_activeteams) {
            dispatch(fetchCompetitionActiveTeams(2));
            if (showLoader) {
                dispatch(uiActions.showLoader());
            }
            onContentLoading && onContentLoading(true);
        }
    }, [
        nhl_activeteams,
        arizona_historical,
        ahl_activeteams,
        showLoader,
        onContentLoading,
        dispatch,
    ]);

    useEffect(() => {
        if (
            !updatingBoard &&
            board &&
            nhl_activeteams &&
            arizona_historical &&
            ahl_activeteams
        ) {
            onContentLoading && onContentLoading(false);
        }
    }, [
        updatingBoard,
        board,
        nhl_activeteams,
        arizona_historical,
        ahl_activeteams,
        onContentLoading,
    ]);

    const handlePlayerSelected = (playerId) => {
        const competitionId = board_metrics_map[playerId].competition.id;
        onPlayerSelected && onPlayerSelected(playerId, competitionId);
    };

    useEffect(() => {
        if (
            board &&
            nhl_activeteams &&
            arizona_historical &&
            ahl_activeteams &&
            (!updatingBoard || reset)
        ) {
            setReset(false);
            dispatch(uiActions.hideLoader());
            console.log("Set Rows");
            setRows(
                // Create an array that has N rows, where N is the row number
                // of the last player item in the board.players array.
                // Each row is a dictionay of the players on that row, mapped
                // by their position.
                // There may be no players on the board, in which case the
                // array will be empty.
                [
                    ...Array(
                        board.players.reduce((max_row, current_player) => {
                            return Math.max(
                                max_row,
                                current_player.location.row
                            );
                        }, 0)
                    ).keys(),
                ].map((row) => {
                    // Filter the board.players array to get the players on the current row. Player rows are 1-indexed.
                    // Then, create a dictionary of the players on the current row, mapped by their position.
                    const row_players = board.players.filter((board_player) => {
                        return board_player.location.row - 1 === row;
                    });

                    const row_players_by_position = {};

                    player_layout_positions.forEach((position) => {
                        row_players_by_position[position] = row_players.filter(
                            (board_player) => {
                                return (
                                    board_player.location.position === position
                                );
                            }
                        );
                        if (row_players_by_position[position].length === 0) {
                            row_players_by_position[position] = null;
                        } else {
                            row_players_by_position[position] = {
                                ...row_players_by_position[position][0].player,
                                ...(board_metrics_map[
                                    row_players_by_position[position][0].player
                                        .id
                                ]
                                    ? {
                                          nameDecoration:
                                              board_metrics_map[
                                                  row_players_by_position[
                                                      position
                                                  ][0].player.id
                                              ].competition.id !== 1
                                                  ? board_metrics_map[
                                                        row_players_by_position[
                                                            position
                                                        ][0].player.id
                                                    ].competition.name
                                                  : "",
                                          nameDecorationClass:
                                              board_metrics_map[
                                                  row_players_by_position[
                                                      position
                                                  ][0].player.id
                                              ].games_played_is_sample
                                                  ? playertile_classes.light
                                                  : null,
                                          metrics:
                                              board_metrics_map[
                                                  row_players_by_position[
                                                      position
                                                  ][0].player.id
                                              ].metrics,
                                          eliteprospects_stats:
                                              board_metrics_map[
                                                  row_players_by_position[
                                                      position
                                                  ][0].player.id
                                              ].eliteprospects_stats,
                                          pillars_count:
                                              board_metrics_map[
                                                  row_players_by_position[
                                                      position
                                                  ][0].player.id
                                              ].pillars_count,
                                          skill_ratings:
                                              players_datastashes[
                                                  row_players_by_position[
                                                      position
                                                  ][0].player.id
                                              ]?.scouting_reports_summaries?.skill_ratings_report?.average_skill_ratings.reduce(
                                                  (acc, rating) => {
                                                      acc[rating.skill_name] =
                                                          rating.average_rating;
                                                      return acc;
                                                  },
                                                  {}
                                              ) || {},
                                      }
                                    : {
                                          cur_jce_team: null,
                                          metrics: null,
                                          pillars_count: null,
                                          eliteprospects_stats: null,
                                          skill_ratings: null,
                                      }),
                            };
                        }
                    });

                    return row_players_by_position;
                })
            );
        }
    }, [
        playerboardId,
        board_metrics_map,
        updatingBoard,
        board,
        nhl_activeteams,
        arizona_historical,
        ahl_activeteams,
        players_datastashes,
        dispatch,
        reset,
    ]);

    return (
        <MainBox>
            {!board && (
                <div className={classes.loading_message}>Loading...</div>
            )}
            {board ? (
                <div className={classes.playerboard}>
                    <div className={classes.board_controls}>
                        <div className={classes.board_controls_label}>
                            Player Tiles
                        </div>
                        <div className={classes.wide_selector}>
                            <RadioButtonGroup
                                options={[
                                    {
                                        value: "trad_stats",
                                        label: "Trad Stats",
                                    },
                                    {
                                        value: "scouting",
                                        label: "Scouting",
                                    },
                                    {
                                        value: "performance",
                                        label: "Metrics",
                                    },
                                    // Check permission and conditionally
                                    // add the "Cap" option if boardMode is 'trade'
                                    ...(canViewContractInformation &&
                                    capTileModeAvailable
                                        ? [
                                              {
                                                  value: "cap",
                                                  label: "Cap",
                                              },
                                          ]
                                        : []),
                                ]}
                                selectedValue={tileViewMode}
                                onValueChange={handleTileViewModeChange}
                                direction="vertical"
                            />
                        </div>
                        <div className={classes.narrow_selector}>
                            <OptionRadioBar
                                options={[
                                    {
                                        id: "trad_stats",
                                        full_label: "Trad Stats",
                                        short_label: "Stats",
                                    },
                                    {
                                        id: "scouting",
                                        full_label: "Scouting",
                                        short_label: "Scouting",
                                    },
                                    {
                                        id: "performance",
                                        full_label: "Metrics",
                                        short_label: "Metrics",
                                    },
                                    // Conditionally add the "Cap" option if use has permission
                                    ...(canViewContractInformation
                                        ? [
                                              {
                                                  id: "cap",
                                                  full_label: "Cap",
                                                  short_label: "Cap",
                                              },
                                          ]
                                        : []),
                                ]}
                                selectedOption={{
                                    id: tileViewMode,
                                }}
                                onSelect={(option) => {
                                    onTileViewModeChange(option.id);
                                }}
                                OptionComponent={({ optionValue }) => (
                                    <div className={classes.page_class_option}>
                                        <div className={classes.full_label}>
                                            {optionValue?.full_label}
                                        </div>
                                        <div className={classes.short_label}>
                                            {optionValue?.short_label}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <PlayerGrid
                        refId={board ? playerboardId : undefined}
                        grid_content={rows || []}
                        updatesAllowed={canUpdateBoard}
                        onTileMoved={handlePlayerMoved}
                        onTileClicked={handlePlayerSelected}
                        onInsertRow={handleInsertRow}
                        onRemoveRow={handleRemoveRow}
                        tileView={tileViewMode}
                        showProjectedFreeAgencyStatus={
                            showProjectedFreeAgencyStatus
                        }
                        showDraftTargetStatus={showDraftTargetStatus}
                        showDraftTeamOverlay={showDraftTeamOverlay}
                        showOwningTeamOverlay={showOwningTeamOverlay}
                        alternateColumnLabels={alternateColumnLabels}
                        rowCountersGroupSize={rowCountersGroupSize}
                    />
                </div>
            ) : (
                <div className={classes.loading_message}>Loading...</div>
            )}
        </MainBox>
    );
};

export default Playerboard;
