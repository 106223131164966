import { useSelector } from "react-redux";
import OptionDropdown from "../controls/OptionDropdown";
import classes from "./NavigationBar.module.css";
import { useEffect, useState } from "react";

const useResponsiveClass = (width) => {
    const [responsiveClass, setResponsiveClass] = useState("");

    useEffect(() => {
        if (width !== undefined) {
            setResponsiveClass(width <= 715 ? classes.responsive : "");
        }
    }, [width]);

    return responsiveClass;
};

const NavigationSetting = ({ label, children }) => {
    return (
        <div className={classes.navigation_setting}>
            {label && <div className={classes.setting_label}>{label}</div>}
            {children}
        </div>
    );
};

const DraftSelector = ({ drafts, selectedDraftId, onSelectDraft }) => {
    const seasons = useSelector((state) => state.seasons.seasons);
    const options = Object.values(drafts)
        .sort((a, b) => b.season_id - a.season_id)
        .map((draft) => ({
            id: draft.id,
            label: seasons[draft.season_id].start_year + 1,
        }));
    const selectedOption = options.find(
        (option) => option.id === selectedDraftId
    );

    return (
        <NavigationSetting label="Draft&nbsp;Year">
            <OptionDropdown
                options={options}
                selectedOption={selectedOption}
                onSelect={(option) => {
                    onSelectDraft(option.id);
                }}
                OptionComponent={({ optionValue }) => {
                    return (
                        <div className={classes.draft_option}>
                            {optionValue?.label}
                        </div>
                    );
                }}
            />
        </NavigationSetting>
    );
};

const TeamSelector = ({ competitionId = 1, selectedTeamId, onSelectTeam }) => {
    const active_teams = useSelector(
        (state) => state.competitions.activeTeams[competitionId]
    );
    const options = (active_teams ? [...active_teams] : [])
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
        .map((team) => ({
            id: team.id,
            label: team.display_name,
            logo_url: team.logo_url,
        }));
    const selectedOption = options.find(
        (option) => option.id === selectedTeamId
    );

    return (
        <NavigationSetting label="Team">
            <OptionDropdown
                options={options}
                selectedOption={selectedOption}
                onSelect={(option) => {
                    onSelectTeam(option.id);
                }}
                OptionComponent={({ optionValue }) => {
                    return (
                        <div className={classes.team_option}>
                            <div className={classes.team_logo}>
                                <img
                                    src={optionValue.logo_url}
                                    alt="team logo"
                                />
                            </div>
                            <div className={classes.team_name}>
                                {optionValue?.label}
                            </div>
                        </div>
                    );
                }}
                columnBreakpoints={[
                    { minWidth: 0, columns: 1 },
                    { minWidth: 320, columns: 2 },
                    { minWidth: 560, columns: 4 },
                ]}
            />
        </NavigationSetting>
    );
};

const NavigationBar = ({ children, width }) => {
    const responsiveClass = useResponsiveClass(width);
    return (
        <div className={`${classes.navigation_bar} ${responsiveClass}`}>
            {children}
        </div>
    );
};

export { DraftSelector, TeamSelector, NavigationSetting };
export default NavigationBar;
