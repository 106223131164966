import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetition } from "../store/competitions-actions";
import { fetchTeam } from "../store/teams-actions";
import { uiActions } from "../store/ui-slice";
import {
    getOrCreateOwnedTeamPlayerboard,
    fetchOwnedPlayerboards,
    syncTeamPlayerboard,
} from "../store/playerboards-actions";
import Playerboard from "./Playerboard";
import useAuth from "../hooks/use-auth";

import { mp_track } from "../mixpanel";

const roster_edit_mp_track = (team, tileViewMode, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team?.id,
        team_name: team?.display_name,
        tile_view_mode: tileViewMode,
    };

    mp_track(event, properties);
};

const RosterEditView = ({
    teamId,
    metricsSeasonId = null,
    playerboardId = null,
    onDefaultVersionSelect = null,
    onPlayerClick,
    onContentLoading,
    triggerReset,
    onResetDone,
}) => {
    const { checkPermission, user } = useAuth();
    const canViewContractInformation = checkPermission(
        "core.can_view_contract_information"
    );

    const dispatch = useDispatch();

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const ahlTeamId = team && team.affiliations[0];

    const competitionId = 1;
    const competition = useSelector(
        (state) => state.competitions.competitions[competitionId]
    );

    const ownedPlayerboards = useSelector(
        (state) => state.playerboards.ownedPlayerboards
    );
    const ownedPlayerboardsFetched = useSelector(
        (state) => state.playerboards.ownedPlayerboardsFetched
    );

    useEffect(() => {
        if (!ownedPlayerboardsFetched) {
            dispatch(fetchOwnedPlayerboards());
        }
    }, [ownedPlayerboardsFetched, dispatch]);

    const teamPlayerboardId = useMemo(
        () =>
            playerboardId ||
            (ownedPlayerboardsFetched &&
                Object.values(ownedPlayerboards).find(
                    (playerboard) => playerboard.team_id === teamId
                )?.id),
        [ownedPlayerboards, ownedPlayerboardsFetched, teamId, playerboardId]
    );

    const [isPlayerboardLoading, setIsPlayerboardLoading] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);

    useEffect(() => {
        if (teamPlayerboardId && !playerboardId) {
            onDefaultVersionSelect && onDefaultVersionSelect(teamPlayerboardId);
        }
    }, [teamPlayerboardId, playerboardId, onDefaultVersionSelect]);

    useEffect(() => {
        if (teamPlayerboardId) {
            setIsSyncing(true);
            onContentLoading && onContentLoading(true);

            dispatch(syncTeamPlayerboard(teamPlayerboardId))
                .then(() => {
                    setIsSyncing(false);
                })
                .catch((error) => {
                    console.error("Error syncing team playerboard:", error);
                    setIsSyncing(false);
                });
        }
    }, [teamPlayerboardId, onContentLoading, dispatch]);

    /*
    const allPlayerboards = useSelector(
        (state) => state.playerboards.playerboards
    );

    const playerboard = useMemo(
        () =>
            teamPlayerboard &&
            allPlayerboards &&
            allPlayerboards[teamPlayerboard.id],
        [teamPlayerboard, allPlayerboards]
    );
    */

    const resetBoard = useCallback(async () => {
        if (teamPlayerboardId) {
            setIsSyncing(true);
            onContentLoading && onContentLoading(true);

            dispatch(syncTeamPlayerboard(teamPlayerboardId, true))
                .then(() => {
                    setIsSyncing(false);
                    onResetDone();
                })
                .catch((error) => {
                    console.error("Error syncing team playerboard:", error);
                    setIsSyncing(false);
                    onResetDone();
                });
        }
    }, [onResetDone, onContentLoading, teamPlayerboardId, dispatch]);

    useEffect(() => {
        if (triggerReset) {
            resetBoard();
        }
    }, [triggerReset, resetBoard]);

    useEffect(() => {
        if (ownedPlayerboardsFetched && team && !teamPlayerboardId) {
            dispatch(
                getOrCreateOwnedTeamPlayerboard(
                    `Roster Editor - ${user.first_name} ${user.last_name} - ${team.display_name}`,
                    teamId
                )
            );
        }
    }, [
        ownedPlayerboardsFetched,
        teamPlayerboardId,
        team,
        teamId,
        user,
        dispatch,
    ]);

    const reduxPlayerTileMode = useSelector((state) => state.ui.playerTileMode);
    const [tileViewMode, setTileViewMode] = useState(reduxPlayerTileMode);

    const teamIdRef = useRef(null);

    useEffect(() => {
        setTileViewMode(reduxPlayerTileMode);
    }, [reduxPlayerTileMode]);

    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            roster_edit_mp_track(team, tileViewMode, "View Roster Edit");
            teamIdRef.current = team.id;
        }
    }, [team, tileViewMode]);

    const onTileViewModeChange = (mode) => {
        roster_edit_mp_track(
            team,
            tileViewMode,
            "Roster Edit Set Tile View Mode",
            {
                new_mode: mode,
            }
        );
        setTileViewMode(mode);
        dispatch(uiActions.setPlayerTileMode(mode));
    };

    const ahl_team = ahlTeamId && teams && teams[ahlTeamId];

    // Fetch required team datastashes
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            onContentLoading && onContentLoading(true);
        }

        if (!competition) {
            dispatch(fetchCompetition(competitionId));
            onContentLoading && onContentLoading(true);
        }

        if (ahlTeamId && teams && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            onContentLoading && onContentLoading(true);
        }
    }, [teamId, teams, ahlTeamId, competition, onContentLoading, dispatch]);

    useEffect(() => {
        if (isPlayerboardLoading) {
            onContentLoading && onContentLoading(true);
        }
    }, [onContentLoading, isPlayerboardLoading]);

    useEffect(() => {
        if (
            team &&
            competition &&
            ahl_team &&
            !isSyncing &&
            !isPlayerboardLoading
        ) {
            onContentLoading && onContentLoading(false);
        }
    }, [
        team,
        competition,
        ahl_team,
        onContentLoading,
        isSyncing,
        isPlayerboardLoading,
    ]);

    /*
    const rosterCount = useMemo(() => {
        if (!playerboard) {
            return null;
        }

        return playerboard.players.reduce((acc, playerEntry) => {
            return acc + (playerEntry.player.is_on_active_roster ? 1 : 0);
        }, 0);
    }, [playerboard]);

    const contractCount = useMemo(() => {
        if (!playerboard) {
            return null;
        }

        return playerboard.players.reduce((acc, playerEntry) => {
            return (
                acc +
                (playerEntry.player.is_signed &&
                playerEntry.player.contract_years >= 1 &&
                !playerEntry.player.is_in_juniors
                    ? 1
                    : 0)
            );
        }, 0);
    }, [playerboard]);
    */

    const handlePlayerClicked = (playerId, competitionId) => {
        // We could eventually compute some peer data here
        onPlayerClick(playerId, competitionId);
    };

    return (
        !triggerReset && (
            <Playerboard
                playerboardId={teamPlayerboardId}
                onPlayerSelected={handlePlayerClicked}
                onTileViewModeChange={onTileViewModeChange}
                metricsSeason={metricsSeasonId}
                capTileModeAvailable={canViewContractInformation}
                boardEventsPrefix={"Roster Editor"}
                showLoader={false}
                onContentLoading={setIsPlayerboardLoading}
            />
        )
    );
};

export default RosterEditView;
