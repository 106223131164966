import React from "react";
import classes from "./PermissionedToggleButton.module.css";

const PermissionedToggleButton = ({
    label,
    labelPosition = "left",
    isSelected = false,
    isAdmin = false,
    materialIcon = "check_circle",
    materialIconUnselected = "circle",
    onChange,
    className = "",
    ...props
}) => {
    // If user is not an admin and the option is not selected, don't render anything
    if (!isAdmin && !isSelected) {
        return null;
    }

    const handleClick = () => {
        if (isAdmin && onChange) {
            onChange(!isSelected);
        }
    };

    const containerClasses = [
        classes.container,
        labelPosition === "bottom" ? classes.vertical : "",
        className,
    ]
        .filter(Boolean)
        .join(" ");

    const buttonClasses = [
        classes.button,
        labelPosition === "bottom" ? classes.vertical : "",
        isAdmin ? classes.admin : "",
        isSelected ? classes.selected : "",
    ]
        .filter(Boolean)
        .join(" ");

    const content = (
        <>
            <div className={classes.iconContainer}>
                <span
                    className={`material-symbols-outlined ${classes.materialIcon}`}
                >
                    {isSelected ? materialIcon : materialIconUnselected}
                </span>
            </div>
            {label && <div className={classes.label}>{label}</div>}
        </>
    );

    return (
        <div className={containerClasses} onClick={handleClick} {...props}>
            <div className={buttonClasses}>{content}</div>
        </div>
    );
};

export default PermissionedToggleButton;
